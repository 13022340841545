import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const ifAuthenticated = (to, from, next) => {
	if (localStorage.LCInternet && JSON.parse(localStorage.LCInternet).token) {
		next();
	} else {
		next({ name: 'Login' });
	}
};

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
		beforeEnter: ifAuthenticated,
	},
	{
		path: "/ingresar",
		name: "Login",
		component: () =>
			import(/* webpackChunkName: "login" */ "../views/Login.vue"),
	},
	{
		path: "/clientes",
		name: "Clients",
		component: () =>
			import(/* webpackChunkName: "clients" */ "../views/Clients.vue"),
		beforeEnter: ifAuthenticated,
	},
	{
		path: "/clientes-moviles",
		name: "MobileClients",
		component: () =>
			import(/* webpackChunkName: "clients" */ "../views/MobileClients.vue"),
		beforeEnter: ifAuthenticated,
	},
	{
		path: "/planes-de-internet",
		name: "InternetPlans",
		component: () =>
			import(
        /* webpackChunkName: "internet-plans" */ "../views/InternetPlans.vue"
			),
		beforeEnter: ifAuthenticated,
	},
	{
		path: "/internet-movil",
		name: "InternetMovil",
		component: () =>
			import(
        /* webpackChunkName: "internet-movil" */ "../views/InternetMovil.vue"
			),
		beforeEnter: ifAuthenticated,
	},
	{
		path: "/antenas",
		name: "Antennas",
		component: () =>
			import(/* webpackChunkName: "antennas" */ "../views/Antennas.vue"),
		beforeEnter: ifAuthenticated,
	},
	{
		path: "/corte-de-caja",
		name: "CorteCaja",
		component: () =>
			import(/* webpackChunkName: "corte-caja" */ "../views/reports/CorteCaja.vue"),
		beforeEnter: ifAuthenticated,
	},
];

const router = new VueRouter({
	mode: "history",
	routes,
});

export default router;
