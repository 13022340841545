const formatCurrency = currency => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
}).format(currency)


const fillToZeros = (num, size) => {
  let code = String(num);
  while (code.length < size) code = "0" + code;
  return code;
}

export {
  formatCurrency,
  fillToZeros
};
