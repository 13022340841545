import Vue from 'vue';
import {
  formatCurrency,
  fillToZeros
} from "./utils";

Vue.filter('formatCurrency', function (value) {
  if (!value) return '$0.00'
  return formatCurrency(value)
})
Vue.filter('fillToZeros', function (value, size) {
  if (!value) return '0'
  return fillToZeros(value, size)
})
