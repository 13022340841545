<template>
  <v-app>
    <nav-bar v-if="showNavBar"></nav-bar>
    <v-main class="main">
      <router-view class="pt-5"/>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {
    NavBar: () => import("./components/NavBar"),
    Footer: () => import("./components/Footer"),
  },
  computed: {
    showNavBar(){
      return this.$route.name !== 'Login'
    }
  }
};
</script>

<style>
.main {
  background-color: #f0f0f0;
}

.background--views {
  background-image: url("./assets/background-min.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  background-color: #f0f0f0;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-corner,
::-webkit-scrollbar {
  background: #f5f5f5;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #e76700;
}

::selection {
  background: #e76800b7;
  color: white;
}



</style>
